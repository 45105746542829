import { graphql } from 'gatsby'
import React from 'react'
import ColumnBackAndForth from '../../../components/atoms/columnBackAndForth'
import SEO from '../../../components/atoms/seo'
import { ITopicPath } from '../../../components/atoms/topicPath'
import ColumnAuthor002 from '../../../components/molecules/columnAuthor002'
import ColumnDetailInner0022 from '../../../components/molecules/columnDetailInner0022'
import ColumnRelationCards0022 from '../../../components/molecules/columnRelationCards0022'
import UsefulFunctionLayout008 from '../../../components/molecules/usefulFunctionLayout008'
import ColumnDetailsBody from '../../../components/organisms/columnDetailsBody'
import ColumnLayout from '../../../components/organisms/columnLayout'
import useRouteParam from '../../../hooks/useRouteParam'

/**
 * コラム記事詳細画面
 * @constructor
 */
const C0022 = () => {
  const paths: ITopicPath[] = [
    { title: 'キンクラコラムTOP', link: useRouteParam('/column') },
    { title: 'コラム記事一覧', link: useRouteParam('/column/list') },
    {
      title: '有給休暇の取得義務化！必要となる企業の対応内容やポイントとは',
    },
  ]

  return (
    <ColumnLayout paths={paths}>
      <SEO
        title="有給休暇の取得義務化！企業がするべき対応やポイントとは"
        description="働き方改革による法改正に伴い、2年間で10日以上の有給休暇が与えられる労働者には、そのうち5日分の有給休暇を取得させることが義務となりました。"
        ogUrl="https://kintaicloud.jp/column/details/C0022/"
        ogType="article"
        ogTitle="有給休暇の取得義務化！企業がするべき対応やポイントとは"
        ogDescription="働き方改革による法改正に伴い、2年間で10日以上の有給休暇が与えられる労働者には、そのうち5日分の有給休暇を取得させることが義務となりました。"
        ogSiteName="キンクラ - 客先常駐特化型のクラウド勤怠管理システム"
        ogImage="https://www.kintaicloud.jp/images/column/details/c0022.jpg"
      />
      <ColumnDetailsBody>
        {/** 記事本体 */}
        <ColumnDetailInner0022 />
        {/** オーサ情報 */}
        <ColumnAuthor002 />
        {/** お役立ち機能 */}
        <UsefulFunctionLayout008 />
        {/** 前後記事への遷移 */}
        <ColumnBackAndForth />
        {/** 関連記事 */}
        <ColumnRelationCards0022 />
      </ColumnDetailsBody>
    </ColumnLayout>
  )
}

export default C0022

export const pageQuery = graphql`
  query C0022 {
    site {
      siteMetadata {
        title
      }
    }
  }
`
