import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import ColumnBtnCard from '../atoms/columnBtnCard'
import ColumnH1 from '../atoms/columnH1'
import useRouteParam from '../../hooks/useRouteParam'

const Wrapper = styled.article`
  section {
    display: flex;
    flex-wrap: wrap;
    justify-content: start;

    span {
      display: none;
    }
  }

  ${BreakPoints.large} {
    margin-bottom: 10px;
  }
`

/**
 * 関連記事カードセット
 * TODO 川上申し送り事項：記事詳細下部に表示されるコンポーネントです。
 * ここでは、表示している記事に関連した他の記事3件が表示される予定です
 * @constructor
 */
const ColumnRelationCards0022 = () => {
  const routeParam = useRouteParam('/column/details/C0021/')
  const routeParam2 = useRouteParam('/column/details/C0023')
  const routeParam3 = useRouteParam('/column/details/C0034')
  return (
    <Wrapper>
      <ColumnH1 label="関連コラム" />
      <section>
        <ColumnBtnCard
          href={routeParam}
          imgId="c0021.jpg"
          label="年次有給休暇とは？付与の条件や日数、時期などを詳しく解説"
          date="2022.04.15"
        />
        <ColumnBtnCard
          href={routeParam2}
          imgId="c0023.jpg"
          label="アルバイトやパートにも有給休暇がある！付与日数や条件は？"
          date="2022.04.15"
        />
        <ColumnBtnCard
          href={routeParam3}
          imgId="c0034.jpg"
          label="有給休暇がもらえない？休職時における有給休暇の付与について"
          date="2022.09.12"
        />
      </section>
    </Wrapper>
  )
}

export default ColumnRelationCards0022
