import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import ColumnH1 from '../atoms/columnH1'
import Introduction003 from './Introduction003'

const Wrapper = styled.article`
  position: relative;
  margin-bottom: 30px;
  .p-txt-date {
    margin-top: -10px;
    color: #ababab;
  }
  section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 16px;
    color: #505050;
    line-height: 1.6em;
    h1 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #f5f5f5;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-bottom: 30px;
      margin-top: 10px;
      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #77d7c9;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }
    h2 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #ffffff;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-top: 10px;
      margin-bottom: 30px;
      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #d8d8d8;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }
    h3 {
      color: #414141;
      font-size: 16px;
      line-height: 1.4em;
      background: #ffffff;
      width: auto;
      padding: 10px 0 8px;
      margin-top: -10px;
      margin-bottom: 20px;
      border-top: solid 2px #e5e5e5;
      border-bottom: solid 2px #e5e5e5;
    }
    p {
      padding-bottom: 30px;
      line-height: 2;
      position: relative;
      span {
        background: linear-gradient(transparent 60%, #ff6 60%);
        font-weight: 600;
      }
      .p-list-link {
        position: absolute;
        top: 6px;
        right: 2px;
        &:before {
          display: block;
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          top: 7px;
          left: -14px;
          border-left: 8px solid #3ec7b3;
          border-top: 4px solid transparent;
          border-bottom: 4px solid transparent;
        }
      }
      &.txt-color-red {
        color: #ff6969;
      }
    }
    .link-div {
      width: 100%;
      display: flex;
      justify-content: left;
      padding-bottom: 30px;
      > div {
        width: 50px;
      }
      .link-p {
        width: calc(100% - 50px);
        .p-list-link {
          position: absolute;
          top: -3px;
          left: 30px;
          &:before {
            display: block;
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            top: 11px;
            left: -14px;
            border-left: 8px solid #3ec7b3;
            border-top: 4px solid transparent;
            border-bottom: 4px solid transparent;
          }
        }
      }
    }
    img {
      width: 100%;
      height: auto;
      padding-bottom: 30px;
    }
  }
  ${BreakPoints.large} {
  }
`

/**
 * コラム記事詳細：記事本体
 * @constructor
 */
const ColumnDetailInner0022 = () => (
  <Wrapper>
    <ColumnH1 label="有給休暇の取得義務化！企業がするべき対応やポイントとは" />
    {/** TODO 川上申し送り事項：▼投稿日をここに表示 */}
    <p className="p-txt-date">2022.04.15</p>
    <section>
      {/** TODO 川上申し送り事項：▼記事投稿（？）で作成された内容がここに入る予定 */}
      <p>
        2019年より<span>「有給休暇の取得義務化」</span>
        が施行されたことで、各企業は社員が安心して有給休暇を取得できるような環境づくりが急務とされています。
        <br />
        今回は、法改正に至った経緯や具体的な概要、有給休暇を実際に取得させるための方法について順に説明をします。さらに、企業が気をつけなければならないポイントについてもあわせて解説をしていきます。
      </p>
      <img src="/images/column/details/c0022.jpg" alt="キンクラコラム画像22" />
      <h1>有給休暇の取得義務化へ至るまでの経緯</h1>
      <p>
        「働き方改革」を推進する一方で、企業での有給休暇の取得率が思うように上昇しない状況が依然として問題視されています。
        <br />
        有給休暇は、本来ならば日頃の仕事疲れをリフレッシュさせるために、社員が希望する時期に取得できるものであるはずです。しかし、現状では
        <span>「仕事が終わらず休む暇がない」「上司や同僚に申し訳ない」</span>
        などの理由から思うように有給休暇を取得しづらいと考える社員も少なくありません。
        <br />
        <br />
        これを受け、法改正により、2019年4月より、有給休暇の一部については取得が義務化されることになりました。
        <br />
        この法改正は、大企業・中小企業等の企業規模を問わず、すべての企業が対象となります。
        <br />
        <br />
        労働に関する法改正は、初めに大企業を対象とした法改正が実施された後に、経過措置を経て中小企業も対象に含める、という段階的な形で実施されるケースが多くみられる中、有給休暇の取得義務化については一斉に対象となった点が大きく注目されています。
        <br />
        これは、国が有給休暇の取得率低下を非常に懸念しており、早急な対応が求められると判断したことに端を発しています。
      </p>
      <h1>有給休暇の取得義務化とは</h1>
      <p>
        ここからは、有給休暇の取得義務化の具体的な内容を説明します。
        <br />
        <br />
        これまでは、社員へ付与する有給休暇の日数についての規定はあったものの、社員へ取得させなければならない日数についての規定はありませんでした。
        <br />
        しかし、今回の法改正により2019年4月以降は、年間で10日以上の有給休暇が与えられる労働者には、そのうち
        <span>5日分の有給休暇を取得させる</span>ことが義務づけられました。
        <br />
        なお、対象となる労働者には、
        <span>正社員のみならず管理監督者や有期契約者も含まれます</span>
        ので、注意が必要です。
      </p>
      <h1>有給休暇の取得方法とは</h1>
      <p>
        有給休暇の取得方法には、主に以下の3種類が挙げられます。使用者は、この3種類の方法から総合的に検討し活用した上で、労働者へ年間5日以上の有給休暇を取得させる必要があります。
      </p>
      <h2>①社員の申請による取得</h2>
      <p>有給休暇の取得を希望した社員が申請を行い、休暇を取得する方法です。</p>
      <h2>②計画的付与による取得</h2>
      <p>
        お盆休みやGW休み、年末年始休業などに有給休暇を付与し、一斉に休業させる方法です。前もって労使協定で具体的な時季を定めておき、その内容に沿った形で有給休暇の付与を行います。
      </p>
      <h2>③時季指定による取得</h2>
      <p>
        前述①や②の方法で年に5日以上の有給休暇の取得をしていない社員に対し、使用者が有給休暇の取得時季を指定し、取得してもらう方法です。
        <br />
        取得時季については、使用者は必ず社員の意見を聴いた上で、意見を尊重した形で付与するように努める必要があります。
      </p>
      <Introduction003 />
      <h1>企業の対応内容</h1>
      <p>
        ここからは、社員へ有給休暇を取得させるための環境を整えるために企業が対応する内容について、順に解説をしていきます。
      </p>
      <h2>①既存の手続きの見直し</h2>
      <p>
        まず、社内の有給休暇の管理体制や取得状況を見直しましょう。
        <br />
        そもそも有給休暇の管理が適切にできていない場合は、申請から残日数の管理までを誰がどのような形で対応するかを検討する必要があります。
        <span>「年次有給休暇取得計画表」</span>を活用する方法も有効です。
        <br />
        また、実際に社員がどの程度の日数を取得しているのかを洗い出し、最終的に目指していく取得率の数値なども具体的に設定していきます。
      </p>
      <h2>②社内風土の改革</h2>
      <p>
        有給休暇の取得を促進するためには、有給休暇を取得することに対して経営者側・社員ともに抵抗がなくなる環境づくりを行うことが重要になります。
        <br />
        特に上司側が、<span>有給休暇の取得は社員の当然の権利</span>
        であることや、上司自身が積極的に有給休暇を取得することを理解することで、部下が安心して有給休暇を取得しやすい雰囲気を作り出すことができるはずです。
        <br />
        社員教育の実施や社内でのコミュニケーションを積極的に取ることで、有給休暇が、会社にとって決してマイナスではないということを理解し、職場風土を改革していきましょう。
      </p>
      <h2>③業務内容や分担内容の見直し</h2>
      <p>
        有給休暇を安心して取得できるようにするためには、休暇を取った社員の
        <span>穴埋めが即座にできる職場体制を整える</span>必要があります。
        <br />
        一部の部署や一部の社員に負担が偏りすぎないよう、業務分担の内容を見直しましょう。また、休暇中の社員へ連絡が行くことのないよう、日頃からマニュアルの整備やシステム構築を実施しておく方法も効果的です。既存の仕事の効率化も常に考えるようにし、
        <span>「本当に必要な作業」</span>
        のみが効率良く行われるように心がけましょう。
      </p>
      <h1>義務化の導入にまつわる注意点</h1>
      <p>
        最後に、有給休暇の義務化を進めていくにあたり気をつけるべきポイントについて説明をします。
        <br />
        <br />
        まず、有給休暇の整備には<span>「基準日」</span>
        を統一させることが必要不可欠です。
        <br />
        基準日とは、社員に有給休暇を与える日をいいます。たとえば、入社時期にばらつきのある会社の場合、入社日からカウントを開始することで社員ごとに基準日がばらばらになってしまい、事務作業の負担が増大する可能性があります。したがって、あらかじめ会社で基準日を統一しておけば、全社員に一斉に有給休暇を与えることができるため、休暇日数の管理がしやすくなります。
        <br />
        なお、有給休暇の取得は、原則として1日または半日単位でカウントする必要があります。
        <span>時間単位での取得分はカウントされない</span>
        点についても覚えておく必要があります。
        <br />
        <br />
        また、有給休暇の「すりかえ」行為にも注意しましょう。
        <br />
        有給休暇のすりかえとは、これまでは有給休暇とは別に付与していた夏季休暇、年末年始休暇を、有給休暇の一斉付与日としての休日に変更することです。これは、結果として社員が自由に取得できるはずの有給休暇が減少してしまうことになるため、不利益変更として扱われる可能性があります。
        <br />
        不利益変更自体に罰則は設けられていませんが、
        <span>あらかじめ労働者側の合意</span>
        を得なければならない事項になります。労働者が訴え出た場合は、その変更が無効になる可能性もあることから、有給休暇を一斉に付与する場合には入念に準備や検討を行う必要があるでしょう。
        <br />
        <br />
        なお、社員へ年に5日以上の有給休暇を与えなかった場合は、罰則として30万円以下の罰金刑に処せられる可能性がありますので、こちらも注意しましょう。
      </p>
      <h1 className="read">まとめ</h1>
      <p>
        有給休暇の取得をはじめ、社員が安心して仕事を続けられるような環境づくりを推奨する取り組みは、さまざまな場所で議論が行われています。
        <br />
        労働力不足が問題視されている昨今では、社員一人ひとりの存在は企業にとって非常に大きな戦力です。この機会に一度社内体制を見直してみてはいかがでしょうか。
      </p>
    </section>
  </Wrapper>
)

export default ColumnDetailInner0022
